import { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { useContext } from "react";
import FcrfContext from "../../Context";
import { DiaryOriginVideo } from "../../utils/dairyDiamondsDetail";

const DiaryGIAInput = ({
  payload,
  handleGIAInput,
  handleSubmit,
  handleGIAMine,
  isActive,
  giainputTextError,
  loader

}) => {
  const getContext = useContext(FcrfContext);
  const { inputError, sidebarCardMainCopy } = getContext;
  const inputRef = useRef(null);
  useEffect(() => {
    if (document.readyState === "complete") {
      inputRef.current.focus();
    }
  }, []);
  
  return (
    <Form className="diary-card" onSubmit={(e) => handleSubmit(e)}>
      <div className="diary-form-content">
        <div className="diary-form-icon">
          <DiaryToolIcon />
        </div>
        <span className="beta-top-mob">[BETA]</span>
        <h5>Create your Diamond diary</h5>
        <Form.Group
          className={`diamond-weight diary-input`}
          controlId="formBasicEmail"
        >
          {/* <Form.Label>GIa Report Number <span className="astric-red">*</span></Form.Label> */}

          <Form.Control
            type="number"
            pattern="[0-9]*/^\d*\.?\d*$/"
            inputMode="decimal"
            placeholder="GIA Report Number"
            name="report_number"
            value={payload?.report_number}
            onChange={(e) => handleGIAInput(e.target.value, e.target.name, 1)}
            ref={inputRef}
            autoFocus={true}
            onWheel={(e) => e.currentTarget.blur()}
          />
          {giainputTextError && (
          <span className="error-text">Please check the number you entered</span>
        )}
        </Form.Group>
        {sidebarCardMainCopy?.originBox &&  
        <Form.Group
          className={`diamond-weight diary-input`}
          controlId="formBasicEmail"
        >
          <Form.Label>Origin / Mine</Form.Label>

          <Form.Select
            aria-label="Default select example"
            onChange={handleGIAMine}
          >
            <option>Origin - unknown</option>
            {DiaryOriginVideo.map((listItem, i) => (
              <option value={listItem?.origin} key={i}>
                {listItem?.origin}
              </option>
            ))}
            <option>Other</option>
          </Form.Select>
        </Form.Group>}
       
        <Button
          className="no-outline-btn bg-btn"
          type="submit"
          disabled={
            (payload?.report_number === "" || inputError || isActive
              ? true
              : false)
          }
        >
          { loader ? <Spinner animation="border" /> : "Generate"}
        </Button>
        {/* <Button
          className="no-outline-btn bg-btn"
          type="submit"
          disabled={payload?.report_number === "" || inputError ? true : false}
        >
         Generate
        </Button> */}
      </div>
    </Form>
  );
};

export default DiaryGIAInput;

const DiaryToolIcon = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#B00020" fillRule="nonzero" cx="36" cy="36" r="36" />
        <circle fill="#B00020" fillRule="nonzero" cx="36" cy="36" r="36" />
        <circle fill="#B021C2" cx="36" cy="36" r="36" />
        <path
          d="M27.052 52.5c-.83 0-1.558-.31-2.18-.928-.624-.619-.935-1.34-.935-2.166V44.25h6.438v-6.548c-1.315.103-2.63-.078-3.946-.542a9.725 9.725 0 0 1-3.53-2.191v-2.99h-2.597L13.5 25.222c1.246-1.168 2.735-2.122 4.465-2.861 1.731-.74 3.497-1.109 5.297-1.109 1.038 0 2.224.163 3.556.49 1.333.327 2.519.85 3.557 1.573V19.5H54v27.586c0 1.512-.528 2.793-1.584 3.841-1.055 1.049-2.345 1.573-3.868 1.573H27.052zm6.438-8.25h12.722v2.836c0 .687.216 1.246.649 1.676.432.43.995.644 1.687.644.692 0 1.255-.215 1.688-.644.432-.43.649-.989.649-1.676V22.594H33.49v2.939l12.514 12.426v2.218H43.77L37.23 33.68l-.883 1.03c-.45.517-.9.912-1.35 1.187-.45.275-.952.533-1.506.773v7.58zM21.704 28.884h4.31v4.59a7.723 7.723 0 0 0 1.739.85c.571.19 1.15.284 1.74.284.865 0 1.747-.232 2.647-.696.9-.464 1.558-.937 1.973-1.418l.883-1.032-3.583-3.557a12.935 12.935 0 0 0-3.738-2.604 10.45 10.45 0 0 0-4.413-.954c-.935 0-1.783.112-2.545.335-.761.223-1.54.524-2.336.902l3.323 3.3zm21.392 18.46H27.052v2.062h16.771c-.208-.206-.38-.49-.52-.85a3.36 3.36 0 0 1-.207-1.212zm-15.995 2.062v-2.062 2.062z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
