import React, { useContext, useEffect, useState } from "react";
import { DiaryCauseShape, DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";

const DiaryShape = ({
  shapInfo,
  diaryInfo,
  data,
  imgScaleVal,
  sidebarCard,
  handleSideBarCard,
  isHide,
  gia_report
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const { results } = gia_report || {};
  const [showFullText, setShowFullText] = useState(false);
  const [paraDetail, setParaDetail] = useState(0);

  // const shapeInfoText = shapInfo?.C24.split("#") || [];
  const resultShapeCode = DiaryCauseShape.filter(
    (elem) => data?.shape_code === elem.code
  );
  const showShapeImgCode = resultShapeCode[0]?.shape;
  const resultShape = DiaryDiamondShapes.filter(
    (elem) => showShapeImgCode === elem.value
  );
  var showShapeImg = resultShape[0]?.img;
  const shapeInfoText = shapInfo?.C24.split("#") || [];
  const plotting_diagram = diaryInfo?.link?.plotting_diagram || "";

  if(results?.data?.shape?.shape_code==="OMB"){
    if(results?.data?.shape?.shape_group==="Cushion"){
      showShapeImg="cushion"
   }
   else{
    showShapeImg = resultShape[0]?.img;
   }
   }

  useEffect(() => {
    const shapeBox = {
      heading: shapInfo?.B22,
      shapeInfoText: shapeInfoText?.length > 0 ? shapeInfoText : null,
      plotting_diagram: plotting_diagram ? plotting_diagram : "",
      showShapeImg,
    };
    setPdfReportData((prev) => ({ ...prev, shapeBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showShapeImg]);

  
  useEffect(() => {
    const paraHeight = document.getElementById('shapeText')?.scrollHeight;
      console.log("paraHeight", paraHeight);
      setParaDetail(paraHeight + 50);
     // eslint-disable-next-line
  }, [showFullText , window.innerWidth]);

  return (
    <section
      className={`diary-card ${sidebarCard?.shapeBox ? "show" : "hide"}`}
      id="shapeBox"
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-shapeBox`}
              name={`shapeBox`}
              onChange={() =>
                handleSideBarCard("shapeBox", sidebarCard?.shapeBox)
              }
              checked={sidebarCard?.shapeBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
      <h4>{shapInfo?.B22}</h4>
      <div className="diary-card-content shape-card-content">
        <p>
        {shapeInfoText[0]} <b>{shapeInfoText[1]}</b> {shapeInfoText[2]}{" "}
          <b>{shapeInfoText[3]}</b> {shapeInfoText[4]} <b>{shapeInfoText[5]}</b>{" "}
          {shapeInfoText[6]} <b>{shapeInfoText[7]}</b> {shapeInfoText[8]}{" "}
          <b>{shapeInfoText[9]}</b> {shapeInfoText[10]}{" "}
          <b>{shapeInfoText[11]}</b> {shapeInfoText[12]}{" "}
          <b>{shapeInfoText[13]}</b> {shapeInfoText[14]}
          
        </p>

        {/* Shape Images */}
        <div
          className={`diary-shape-view ${!diaryInfo?.link?.plotting_diagram ? "multi-shape-img" : ""}`}
        >
          {diaryInfo?.link?.plotting_diagram && (
            <img src={diaryInfo?.link?.plotting_diagram} alt="diamond shape structure" />
          )}
          {!diaryInfo?.link?.plotting_diagram && (
            <>
              <img
                className="diary-shape-img-pair"
                src={`/images/diamond-shape-${showShapeImg}-front.svg`}
                alt={showShapeImg}
                style={{ transform: `scaleY(${imgScaleVal})` }}
              />
              <img
                className="diary-shape-img-pair"
                src={`/images/diamond-shape-${showShapeImg}-back.svg`}
                alt={showShapeImg}
                style={{ transform: `scaleY(${imgScaleVal})` }}
              />
            </>
          )}
        </div>
        

       
      </div>
      {!!shapInfo?.D22 && (
         <div className="diary-card-content remove-gaps ">
         <div
           className={`dec-para-cause ${
             showFullText ? "dec-para-full-height" : "dec-para-zero-height"
           }`}
           style={{ height: `${showFullText ? `${paraDetail}px` : "0px"}` }}
         >
           <h5>{shapInfo?.D22}</h5>
           <p id="shapeText" style={{
           }}>
             {shapInfo?.D24}
           </p>
         </div>
 
         {!!shapInfo?.D22 && (
           <span
             className="readmore-text"
             onClick={() => setShowFullText(!showFullText)}
           >
             {showFullText ? "Read Less" : "Read More..."}
           </span>
         )}
         </div>
        
      )}
     
    </section>
  );
};

export default DiaryShape;
