/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { rarityInstance } from "../../config/axios";
import {
  ArgyleDiamonds,
  ArgyleDiamondShapes,
} from "../../utils/argyleDiamondsDetail";
import { DiamondColors, DiamondShapes } from "../../utils/diamondsDetail";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/layout";
import Loader from "../loader/loader";
import { getlocalStorage } from "../../utils/getlocalStorage";

import "./savedReport.css";

const SavedReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectItem, setSelectItem] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  useEffect(() => {
    getReport();
  }, []);

  const getReport = async () => {
    try {
      const response = await rarityInstance().get("report/getargylereport");
      const { status, getReport } = response?.data;
      if (status === 200) {
        setLoading(false);
        //const { report: reports } = getReport?.[0] || {};
        setReportsData(getReport);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTime = (time) => {
    let d = new Date(time);
    let pm = d.getHours() >= 12;
    let hour12 = d.getHours() % 12;
    if (!hour12) hour12 += 12;
    let minute = d.getMinutes();
    let date = d.getDate();
    let month = d.toLocaleString("en-US", { month: "short" });
    let year = d.getFullYear();
    return `${month}' ${dateSuffix(date)} ${year} ${hour12}:${
      minute < 10 ? "0" + minute : minute
    } ${pm ? "PM" : "AM"}`;
  };

  function dateSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const handleSelect = (id) => {
    setSelectItem(true);
    setSelectedPostId(id);
  };

  
  const getCalculation = async (id) => {
    const getReportDataById = reportsData.find((el) => el?.report?.postId === id);
    const {
      report
    } = getReportDataById || {}
    let diamondColor = DiamondColors.filter(
      (el) => el.value === report?.data?.color
    );

    let diamondShape = DiamondShapes.filter(
      (el) => el.value === report?.data?.shape
    );
    const copyfilterdata = { ...report };
    const { is_free, is_subscription, is_role, service } = getlocalStorage();
    copyfilterdata.free = is_free;
    copyfilterdata.subscription = is_subscription || "not active";
    copyfilterdata.role = is_role;
    copyfilterdata.type = "Argyle Search History";
    copyfilterdata.service = service;
    await rarityInstance().post("report/argyleWebhook", copyfilterdata);
    navigate("/argyle-report", {
      state: {
        id: id,
        action: "Get",
        rarityColor: {
          color: diamondColor?.[0]?.img,
          shape: diamondShape?.[0]?.img,
        },
      },
    });
  };


  return (
    <div className="saved-report-page">
      {loading && <Loader type="progress" />}
      <Layout>
        <div className="container">
          <div className="saved-report-wrapper">
            <div className="saved-report-title">
              <h1>Argyle Search History</h1>
            </div>
            {Array.isArray(reportsData) && reportsData?.length > 0
              ? reportsData
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  ?.map((el) => {
                    const {
                      report
                    } = el ||{}
                    return (
                      <>
                        <SavedCard
                          weight={report?.data?.weight}
                          color={report?.data?.color}
                          shape={report?.data?.shape}
                          saturation={report?.data?.saturation}
                          postId={report?.postId}
                          selectedPostId={selectedPostId}
                          time={
                            el?.timestamp &&
                            getTime(new Date(el?.timestamp).toString())
                          }
                          selectItem={selectItem}
                          handleSelect={handleSelect}
                          getCalculation={getCalculation}
                        />
                      </>
                    );
                  })
              : ""}
            <div className="divider"></div>
          </div>
        </div>
        {/* <div className="saved-report-footer" id="savedBtn">
          <div className="container">
            <div className="saved-bottom-wrapper">
              <Button
                onClick={() => getCalculation(selectedPostId)}
                disabled={!selectItem}
              >
                Process
              </Button>
            </div>
          </div>
        </div> */}
      </Layout>
    </div>
  );
};

export default SavedReport;

const SavedCard = ({
  weight,
  color,
  shape,
  saturation,
  postId,
  selectedPostId,
  time,
  handleSelect,
  getCalculation,
}) => {

  let rarityColor = ArgyleDiamonds.filter((el) => el.color.value === color);
  let rarityShape = ArgyleDiamondShapes.filter((el) => el.value === shape);
  return (
    <div
      id={`${postId}`}
      className={`saved-card-wrapper ${
        postId === selectedPostId ? "active" : ""
      } `}
      onClick={() => getCalculation(postId)}
      role="presentation"
      key={postId}
    >
      <div className="saved-card-content">
        <span>
          {saturation}
          {"  "}
          {color}
          {"  "}
          {`${weight}ct`}
        </span>
        <div className="saved-card-date">
          Results reflect the data as of{" "}
          <span className="diamond-date-td">{time}</span>
        </div>
      </div>
      <div className="saved-card-img">
        <img
          className={`diamond-saturation ${saturation}`}
          src={`images/diamond-${rarityColor[0]?.color?.img}-${rarityShape[0]?.img}.svg`}
          alt={rarityColor[0]?.color?.img}
        />
      </div>
    </div>
  );
};
